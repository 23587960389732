/* 
    <SeoHeader
        title={data.title}
        description={data.overview}
        type="webapp"
        name={(data.author) && `${data.author.user.first_name} ${data.author.user.last_name}`}
        thumbnail={data.thumbnail}
        url={window.location.href}
      />
*/

import Head from 'next/head'

const SeoHeader = (props) => {
  
  const defaultURL = "https://leadboard.ng"
  let image = (props.thumbnail ? props.thumbnail : '/favicon.svg');

  // attach default link protocol
  if(!image.includes('http')){
    image = defaultURL+image
  }

  const defaults = {
    title:'instinctHub - Learning Made Easy',
    description: "Welcome to our learning platform! Here, you'll find a wealth of knowledge and resources at your fingertips. Whether you're a student, professional, or lifelong learner, we've got something for you. Our platform is designed to be interactive, engaging and easy-to-use. With a variety of courses, videos, assessments, and interactive activities, you'll be able to learn at your own pace and on your own schedule. We are committed to providing you with the best educational experience, and our team of experienced educators is always available to support you. Join us today and start expanding your horizons!",
    name: "instinctHub Techsavvy Limited",
    type: "webapp",
    favicon: defaultURL+"/favicon.svg"
  }

  return (
    <Head>
      <title>{props.title ? props.title : defaults.title}</title>
      <meta name="description" content={props.description ? props.description : defaults.description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href={process.env.NODE_ENV === "development" ? "/favicon.svg" : defaultURL+"/favicon.svg"} />
      <meta property="og:type" content={props.type ? props.type : defaults.type} />
      <meta property="og:title" content={props.title ? props.title : defaults.title} />
      <meta property="og:description" content={props.description ? props.description : defaults.description} />
      <meta property="og:image" content={image ? image : defaults.favicon} />
      <meta property="og:url" content={defaultURL} />
      <meta name="twitter:creator" content={props.name ? props.name : defaults.name} />
      <meta name="twitter:card" content={props.type ? props.type : defaults.type} />
      <meta name="twitter:title" content={props.title ? props.title : defaults.title} />
      <meta name="twitter:description" content={props.description ? props.description : defaults.description} />
    </Head>
  );
};

export default SeoHeader;